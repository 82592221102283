<template>
  <div class="page">
    <div class="page-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="page-right">
      <div class="tab">
        <span class="span">护理计划</span>
      </div>
      <div class="scroll">
        <div class="info">
          <div class="add">
            <el-button type="primary" size="mini" @click="openAddNursing">新增</el-button>
          </div>
          <div class="table">
            <el-table :data="tableData" height="100%" border>
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="name" label="工作编码"></el-table-column>
              <el-table-column label="计划序号"></el-table-column>
              <el-table-column label="护理内容"></el-table-column>
              <el-table-column label="服务时间"></el-table-column>
              <el-table-column label="执行频率"></el-table-column>
              <el-table-column label="任务类型"></el-table-column>
              <el-table-column label="所属街道"></el-table-column>
              <el-table-column label="操作" width="260">
                <!-- slot-scope="scope" -->
                <template>
                  <!-- <div class="flex">
                    <span>编辑</span>
                    <span @click="openConfig">配置频率</span>
                    <span>删除</span>
                  </div> -->
                  <el-button type="primary" size="mini">编辑</el-button>
                  <el-button type="primary" size="mini" @click="openConfig">配置频率</el-button>
                  <el-button type="danger" size="mini">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagination">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
              background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
              :total="400">
            </el-pagination>
          </div>
          <AddNursing v-if="showAddNursing" @close="closeAddNursing"></AddNursing>
        </div>
      </div>
    </div>
    <BasicInfoPopup v-if="showBasicInfoPopup" @close="closeBasicInfoPopup"></BasicInfoPopup>

    <Config v-if="showConfig" @close="closeConfig"></Config>
  </div>
</template>

<script>
import AddNursing from '../../bed/service/nursing/addNursing.vue'
import BasicInfoPopup from '../../monitoring/basicInfoPopup.vue'
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
import Config from './config.vue'
export default {
  components: {
    AddNursing,
    BasicInfoPopup,
    UserBasicInfo,
    Config
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['服务工单管理', '护理计划', '用药计划', '康复计划']),
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1'
      }],
      showAddNursing: false,
      showBasicInfoPopup: false,
      showConfig: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAddNursing () {
      this.showAddNursing = true
    },
    closeAddNursing () {
      this.showAddNursing = false
    },
    openBasicInfoPopup () {
      this.showBasicInfoPopup = true
    },
    closeBasicInfoPopup () {
      this.showBasicInfoPopup = false
    },
    openConfig () {
      this.showConfig = true
    },
    closeConfig () {
      this.showConfig = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  display: flex;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: #fff;

  .page-left {
    width: 270px;
    height: 100%;

    .title {
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding-left: 34px;
      padding-top: 5px;
      box-sizing: border-box;
      background: url('../../img/dialogTitleBg.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
    }

    .scroll {
      overflow: scroll;
      width: 100%;
      height: 752px;
      margin-top: 5px;
      border-radius: 6px;
      background-color: rgba(29, 64, 112, .33);

      .list {
        display: flex;
        align-items: center;
        width: 610px;
        height: 50px;
        color: #01EEFD;
        font-size: 16px;
        cursor: pointer;

        div {
          width: 60px;
          text-align: center;
        }

        .flex2 {
          width: 100px;
        }

        .flex3 {
          width: 150px;
        }

        .flex4 {
          width: 300px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .color {
        position: sticky;
        top: 0;
        left: 0;
        padding: 4px 0;
        z-index: 88;
        background-color: rgba(14, 40, 75, 1);
      }

      .movetop {
        // animation: moveani var(--time) infinite linear normal;
      }

      @keyframes moveani {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(var(--moveTop));
        }
      }

      .movetop:hover {
        animation-play-state: paused;
      }
    }
  }

  .page-right {
    position: relative;
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;
    box-sizing: border-box;
    background-color: #fff;

    .tab {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #01EEFD;

      span {
        height: 38px;
        line-height: 38px;
        margin-right: 16px;
        padding: 0 9px;
        color: #fff;
        font-size: 22px;
        background-color: #D5D5D5;
        cursor: pointer;
      }

      .span {
        background-color: #409EFF;
      }
    }

    .scroll {
      width: 100%;
      height: calc(100% - 70px);
      margin-top: 10px;

      .info {
        position: relative;
        width: 100%;
        height: 100%;

        .add {
          display: flex;
          align-items: center;
          width: 100%;
          height: 46px;
        }

        .table {
          width: 100%;
          height: calc(100% - 160px);

          /deep/.el-table .cell {
            text-align: center;
            color: #000;
          }

          .flex {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            font-size: 16px;

            span {
              cursor: pointer;
            }
          }
        }

        .pagination {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
