<template>
  <div class="popup">
    <div class="popup-box">
      <div class="popup-title">
        <div class="popup-top"></div>
        <div class="popup-bottom">
          <div class="popup-bottom-text">
            <span>配置频率</span>
          </div>
        </div>
      </div>

      <div class="popup-content">
        <div class="popup-content-box">
          <div class="info-close" @click="close">
            <span class="el-icon-close"></span>
          </div>
          <div class="info-content">
            <div class="title">
              <span>请设置护理项目类型</span>
            </div>
            <div class="info-table">
              <div class="info-tr info-tr1">
                <div class="info-td info-border">
                  <span>执行频率</span>
                </div>
                <div class="info-td info-td5">
                  <el-radio-group v-model="radio">
                    <el-radio label="1">月任务</el-radio>
                    <el-radio label="2">周任务</el-radio>
                    <el-radio label="3">天任务</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="info-tr">
                <div class="info-td info-border">
                  <span>任务类型</span>
                </div>
                <div class="info-td info-td5">
                  <div class="info-td info-td2 info-padding">
                    <el-radio-group v-model="radio">
                      <el-radio label="1">一般任务</el-radio>
                      <el-radio label="2">关键任务</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="info-td info-border info-left-border">
                    <span>语音提醒</span>
                  </div>
                  <div class="info-td info-td2">
                    <el-radio-group v-model="radio">
                      <el-radio label="1">否</el-radio>
                      <el-radio label="2">是</el-radio>
                    </el-radio-group>
                  </div>
                </div>

              </div>
            </div>
            <div class="title">
              <span>请选择时间</span>
            </div>
            <div class="info-table">
              <el-checkbox-group v-model="checkList">
                <el-checkbox v-for="(item, index) in checks" :key="index" :label="index + 1 + ''">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="info-btns">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" class="blue" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      radio: '',
      checkList: [],
      checks: Object.freeze(['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00', '24:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30'])
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .82);
  box-sizing: border-box;

  .popup-box {
    display: flex;
    flex-direction: column;
    width: 1160px;
  }

  .popup-title {
    display: flex;
    flex-direction: column;

    .popup-top {
      position: relative;
      width: 87px;
      height: 6px;
      transform: skew(30deg);
      background-color: #03BCD7;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -6px;
        width: 10px;
        height: 6px;
        background-color: #081C38;
        transform: skew(-50deg);
      }
    }

    .popup-bottom {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 30px;
      background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
      transform: skew(30deg);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 30px;
        background-color: #081C38;
        transform: skew(-55deg);
      }

      .popup-bottom-text {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        box-sizing: border-box;
        transform: skew(-30deg);
        color: #fff;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .popup-content {
    width: 100%;
    height: 600px;
    margin-top: 11px;
    padding: 10px;
    background: linear-gradient(93deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .popup-content-box {
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 30px;
      border: 4px solid rgba(1, 124, 219, .27);
      box-sizing: border-box;

      .info-close {
        position: absolute;
        top: -15px;
        right: -40px;
        width: 100px;
        height: 45px;
        line-height: 60px;
        text-align: center;
        transform: rotate(30deg);
        background: linear-gradient(117deg, #1E58A6 0%, #1C4EA1 53%, #2866B1 100%);
        box-shadow: inset 0px -13px 0px 1px #2661AB;
        color: #fff;
        font-size: 24px;
        box-sizing: border-box;
        cursor: pointer;

        span {
          transform: rotate(-30deg);
        }
      }

      .info-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title {
          width: 100%;
          height: 40px;
          line-height: 40px;
          color: #fff;
          font-size: 18px;
        }

        .info-table {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 1px solid #fff;
          box-sizing: border-box;

          .info-tr {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 46px;

            .info-td {
              flex: 1;
              height: 46px;
              line-height: 46px;
              text-align: center;
              color: #fff;
              font-size: 18px;
            }

            .info-border {
              border-right: 1px solid #fff;
              box-sizing: border-box;
            }

            .info-left-border {
              border-left: 1px solid #fff;
              box-sizing: border-box;
            }

            .info-td2 {
              flex: 2;
              text-align: left;
              padding: 0 30px;
              box-sizing: border-box;
            }

            .info-td5 {
              display: flex;
              flex-direction: row;
              align-items: center;
              flex: 5;
              text-align: left;
              padding: 0 30px;
              box-sizing: border-box;
            }

            .info-padding {
              padding: 0;
            }

            .el-radio {
              color: #fff;
            }

            /deep/.el-radio__label {
              font-size: .225rem;
            }

            /deep/.el-radio__inner {
              background-color: transparent;
            }

            /deep/.el-radio__input.is-checked+.el-radio__label {
              color: #01EEFD;
            }

            /deep/.el-radio__input.is-checked .el-radio__inner {
              background-color: #01EEFD;
              border-color: #01EEFD;
            }
          }

          .info-tr1 {
            border-bottom: 1px solid #fff;
            box-sizing: border-box;
          }

          .el-checkbox-group {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;

            .el-checkbox {
              width: 12.5%;
              height: 30px;
              margin-right: 0;
            }

            /deep/.el-checkbox__inner {
              background-color: transparent;
            }

            /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
              background-color: #01EEFD;
            }

            /deep/.el-checkbox__label {
              color: #fff;
              font-size: 16px;
            }

            /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
              color: #01EEFD;
            }
          }
        }
      }

      .info-btns {
        position: absolute;
        left: 0;
        bottom: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        .el-button {
          margin: 0 50px;
          background-color: #33465C;
          border: none;
          color: #fff;
        }

        .blue {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
